import { render, staticRenderFns } from "./AppHeaderAuth.vue?vue&type=template&id=d341ce82"
import script from "./AppHeaderAuth.vue?vue&type=script&lang=js"
export * from "./AppHeaderAuth.vue?vue&type=script&lang=js"
import style0 from "./AppHeaderAuth.vue?vue&type=style&index=0&id=d341ce82&prod&module=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppBtn: require('/builds/project/volu/frontend/components/app/AppBtn.vue').default,AppHeaderLogin: require('/builds/project/volu/frontend/components/app/header/AppHeaderLogin.vue').default})
